.employer {
    margin-top: 2rem;
}

.section {
    margin-top: 1rem;
}

.assignment-list {
    margin-top: 2rem;
}

.achievement {
    margin-bottom: 1rem;
    padding-left: 0.5rem;
}

.keywords {
    margin: 0.5rem 0;
}